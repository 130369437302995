body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(0, 0%, 98%);
  min-height: 100vh;
}

.notification-area {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  height: 50vh;
  left: 0;
  right: 0;
  overflow: hidden;
}

.main-snackbar {
  position: absolute !important;
  width: 400px;
  left: 50%;
  transform: translateX(-50%) translateY(calc(100% + 20px));
  transition: transform linear 0.25s;
  bottom: 20px;
}

.main-snackbar.is-active {
  transform: translateX(-50%) translateY(0);
}

#video-bkg {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  max-width: none;
  animation: 1s linear forwards fadeIn;
  filter: blur(3px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
